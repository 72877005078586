/* ---------------------------------- */
/* Style Unit Conversion */
/* ---------------------------------- */
#unitConversion {
  h1 {
    margin-bottom: 25px;
  }

  .conversionElement {
    padding-top: 35px;
    margin-bottom: 20px;
    border-top: 1px solid $borderColorBlack;
  }

  fieldset {
    margin-bottom: 10px;
  }

  .input-group {
    .input-group-prepend,
    .input-group-append {
      .input-group-text {
        color: $color2;
        background-color: $backgroundColorBlue;
        border: 1px solid $backgroundColorBlue;
      }
    }
  }
  .form-control {
    color: $backgroundColorBlue;
    border: 1px solid $backgroundColorBlue;
    padding: 17px 10px 16px 10px;
    height: calc(1.5em + 29px + 2px);
  }

  .output {
    cursor: copy;
  }

  button.changeButton {
    width: 100%;
    border: none;
    border-radius: 7.5px;
    padding: 17px 20px 16px 20px;
    background-color: $backgroundColorGreen;
    margin-top: 10px;
    margin-bottom: 25px;
    h4 {
      text-align: center;
    }
  }
  .text {
    border-top: 1px solid $borderColorBlack;
    padding-top: 35px;
    margin-bottom: 100px;
    a {
      color: $color1;
    }
  }
}
