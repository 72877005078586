/* ---------------------------------- */
/* Style Filter Overlay */
/* ---------------------------------- */
#filterOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $backgroundColorMain;
  z-index: 75;
  padding-bottom: 100px;
  transform: translateX(-100%);

  -webkit-transition: transform 0.5s ease-in-out;
  -moz-transition: transform 0.5s ease-in-out;
  -o-transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;

  &.open {
    transform: translateX(0);
    header,
    footer {
      transform: translateX(0);
      overflow-y: scroll;
    }
  }

  header {
    transform: translateX(-100%);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: $backgroundColorMain;
    border-bottom: 1px solid $borderColorBlack;
    z-index: 1;

    .container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      height: 75px;
    }
    .close {
      height: 22.5px;
      width: 22.5px;
      opacity: 1;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }

    .back {
      height: 22.5px;
      width: 14.35px;
      opacity: 1;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  footer {
    transform: translateX(-100%);
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $backgroundColorMain;
    border-top: 1px solid $borderColorGray;

    .container {
      height: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      height: 100px;
    }

    .row {
      & > div:nth-child(odd) {
        padding-right: 5px;
        padding-left: 0;
      }

      & > div:nth-child(even) {
        padding-left: 5px;
        padding-right: 0;
      }
    }

    button {
      width: 100%;
      text-align: left;
      padding: 17px 20px 16px 20px;
      border: none;
      border-radius: 7.5px;

      &.resetButton {
        background-color: $backgroundColorLightgray;
      }
      &.resultButton {
        background-color: $backgroundColorGreen;
      }
    }
  }

  .filterOverlayMain {
    margin-top: 75px;
    margin-bottom: 100px;
    overflow-y: scroll;
    height: calc(100vh - 175px);

    .filterAccordion {
      margin-bottom: 125px;
      .container {
        padding-left: 0;
        padding-right: 0;
      }

      .methods {
        & > div:nth-child(odd) {
          padding-right: 5px;
          padding-left: 0;
        }

        & > div:nth-child(even) {
          padding-left: 5px;
          padding-right: 0;
        }

        .method {
          border-radius: 10px;
          padding: 18px 10px;
          min-height: 92px;
          margin-top: 5px;
          margin-bottom: 5px;
          width: 100%;
          background: transparent url('../../images/icons/cross_white.svg')
            right 10px top 10px no-repeat;
          background-size: 10px 10px;
          border: none;

          h3 {
            color: $color2;
          }

          &.deactivated {
            opacity: 0.25;
            h3 {
              text-decoration: line-through;
            }
          }
        }
        .method-1 {
          background-color: $backgroundColorBlue;
        }
        .method-2 {
          background-color: $backgroundColorLightgray;
        }
        .method-3 {
          background-color: $backgroundColorGreen;
        }
        .method-4 {
          background-color: $backgroundColorCyan;
        }
      }

      // Large devices (desktops, less than 1200px)
      @media only screen and (min-width: 1199.98px) {
        .methods > div {
          padding-right: 10px !important;
          padding-left: 10px !important;
        }
        .methods > div:first-child {
          padding-left: 0 !important;
        }
        .methods > div:last-child {
          padding-right: 0 !important;
        }
      }

      .filters {
        ul {
          list-style: none;
          margin-bottom: 0;

          li {
            margin-bottom: 11px !important;
          }
        }
        .checkbox {
          position: absolute; // take it out of document flow
          opacity: 0; // hide it

          & + label {
            position: relative;
            cursor: pointer;
            padding: 0;

            h5 {
              padding-left: 45px;
              padding-right: 40px;
              display: inline-block;
              width: 100%;

              &.hover {
                color: $color4;
              }
            }
          }

          // Box.
          & + label:before {
            content: '';
            margin-right: 20px;
            display: inline-block;
            vertical-align: text-top;
            width: 27px;
            height: 27px;
            border: 3px solid $borderColorBlue;
            border-radius: 3px;
            position: absolute;
            top: -4px;
            left: 0;
          }

          // Box hover
          &:hover + label:before {
            border: 3px solid $backgroundColorGreen;
          }

          // Checkmark. Could be replaced with an image
          &:checked + label:after {
            content: '';
            position: absolute;
            left: 0;
            top: -3.5px;
            height: 27px;
            width: 27px;
            border: none;
            background: white;
            background: url('../../images/icons/checkmark.svg') no-repeat center
              center;
            background-size: 15.1px 18.8px;
          }
        }

        .toggleFilterInfo {
          cursor: pointer;
          position: absolute;
          right: 15px;
          img.infoImg {
            height: 25px;
            width: 25px;
          }
        }
      }

      .card {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid $borderColorGray;

        ul {
          list-style: none;

          &.activFilters {
            margin-left: 21px;
          }

          &:empty {
            margin-bottom: 0;
          }

          li {
            margin-bottom: 6px;
          }
        }

        .card-header {
          padding: 21px 21px 19px 21px;
          border-bottom: none;
          background: transparent;
          position: relative;
          cursor: pointer;

          &::before {
            content: '';
            position: absolute;
            right: 20px;
            top: 0;
            bottom: 0;
            width: 25px;
            background: transparent url('../../images/icons/arrow_down.svg')
              right 0 center no-repeat;
            background-size: 22.5px 14.35px;
            transform: rotate(180deg);
          }

          &.open::before {
            transform: rotate(0deg);
          }
        }

        .card-body {
          padding: 10px 21px 14px 21px;

          &:empty {
            padding: 0;
            min-height: 0;
          }
        }
      }
    }

    .filterInfo {
      position: absolute;
      top: 76px;
      bottom: 101px;
      left: 0;
      right: 0;
      overflow-y: scroll;
      height: calc(100vh - 177px);
      z-index: 10;
      background-color: $backgroundColorMain;

      h1 {
        margin-top: 30px;
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 30px;
      }
    }
  }
}
