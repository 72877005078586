/* ---------------------------------- */
/* Allgemeiner Style für die Webapp */
/* ---------------------------------- */

img {
  display: block;
  max-width: 100%;
}

select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.customTable {
  display: block;
  border-color: grey;
  margin-bottom: 25px;

  header {
    width: 100%;
  }

  .tr {
    border-color: inherit;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }
  .colSpan2 {
    width: 100% !important;
  }

  .th {
    display: inline-block;
    vertical-align: inherit;
    font-weight: bold;
    text-align: -internal-center;
    border: 1px solid #dee2e6;
    vertical-align: middle;
    padding: 0.75rem;
    width: 100%;
  }
  .td {
    display: inline-block;
    vertical-align: inherit;
    border: 1px solid #dee2e6;
    vertical-align: middle;
    padding: 0.75rem;
    width: 100%;
  }
}

/* ---------------------------------- */
/* Style Header */
/* ---------------------------------- */
header#header {
  position: relative;
  z-index: 50;
  border-bottom: 1px solid $borderColorBlack;
  height: 75px;
  margin-bottom: 30px;

  /* ---------------------------------- */
  /* Style Navigation */
  /* ---------------------------------- */
  #navigation {
    height: 100%;
    padding: 0;

    .navbar-toggler-icon {
      background-image: url(../../images/icons/menuBurger.svg);
    }

    .navbar-toggler {
      padding: 0.25rem 0;
      border: none;
      &:active {
        .navbar-toggler-icon {
          background-image: url(../../images/icons/menuBurger.svg);
        }
      }
    }

    &.closed {
      .menu {
        transform: translateX(0);
      }
    }

    .navbar-collapse {
      flex-basis: 100%;
      flex-grow: 1;
      align-items: center;
      display: block;

      &.collapsing {
        position: static;
        height: auto;
        overflow: visible;
        transition: none;
      }
    }

    .menu {
      position: fixed;
      transform: translateX(100%);
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      -webkit-transition: transform 0.5s ease-in-out;
      -moz-transition: transform 0.5s ease-in-out;
      -o-transition: transform 0.5s ease-in-out;
      transition: transform 0.5s ease-in-out;

      background: $backgroundColorGradient1;
      background: -moz-linear-gradient(
        $backgroundColorDeg,
        $backgroundColorGradient1 0%,
        $backgroundColorGradient2 100%
      );
      background: -webkit-linear-gradient(
        $backgroundColorDeg,
        $backgroundColorGradient1 0%,
        $backgroundColorGradient2 100%
      );
      background: linear-gradient(
        $backgroundColorDeg,
        $backgroundColorGradient1 0%,
        $backgroundColorGradient2 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$backgroundColorGradient1",endColorstr="$backgroundColorGradient2",GradientType=1);

      .navbar-toggler-icon {
        background-image: url(../../images/icons/menuCross.svg);
        height: 22.5px;
        width: 22.5px;
      }

      .navbar-toggler {
        border: none;

        &:active {
          .navbar-toggler-icon {
            background-image: url(../../images/icons/menuCross.svg);
          }
        }
      }

      .nav-item {
        .nav-link {
          &.active {
            h2 {
              color: $color4;
            }
            &:hover {
              h2 {
                color: $color4;
              }
            }
          }
          &:hover {
            h2 {
              color: $color5;
            }
          }
        }
      }

      header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        border-bottom: 1px solid $borderColorWhite;
        margin-bottom: 25px;

        .container {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          height: 75px;
        }
      }

      .lang {
        padding: 0 0 50px 0;

        .langItem {
          cursor: pointer;
          padding: 6px 10px 4px 10px;
          border: 2px solid $color4;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          color: $color4;
          display: inline-block;
          &.active {
            color: #fff;
            background: $color4;
            &:first-child {
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
              border-top-right-radius: 0px;
              border-bottom-right-radius: 0px;
            }
            &:last-child {
              border-top-left-radius: 0px;
              border-bottom-left-radius: 0px;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
            }
          }
          &.inverse {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
          }

          > span {
            font-size: 20px;
            font-family: 'Graphik';
            line-height: 1em;
            display: block;
          }
        }
      }
      // .lang2.inverse {
      //   /*border-radius: 10px;*/
      //   cursor: pointer;
      //   padding: 5px 10px;
      //   border: 2px solid #00356d;
      //   border-top-left-radius: 10px;
      //   border-bottom-left-radius: 10px;
      //   border-top-right-radius: 0px;
      //   border-bottom-right-radius: 0px;
      // }
    }
  }

  .overlayToggle {
    cursor: pointer;
  }
}

/* ---------------------------------- */
/* Style Modal / Overlay */
/* ---------------------------------- */
.modal-backdrop {
  display: none !important;
}

.modal {
  display: none !important;
}
