/* ---------------------------------- */
/* Style Demolding Plastic */
/* ---------------------------------- */
#demoldingPlastic {
  margin-bottom: 100px;
  h1 {
    margin-bottom: 20px;
  }

  h3 {
    line-height: 1.25em;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media only screen and (max-width: 767.98px) {
    h3 {
      text-align: left;
      padding-left: 10px;
    }
  }
  @media only screen and (max-width: 374.98px) {
    h3 {
      padding-left: 5px;
      font-size: 20px;
    }
  }

  .customTable,
  .th,
  .td {
    text-align: center;
    border: none;
  }

  .td {
    border-left: solid 1px $borderColorGray;
    border-bottom: solid 1px $borderColorGray;
  }

  .tr {
    border-right: solid 1px $borderColorGray;
  }

  .header .th {
    background-color: $backgroundColorBlue;
    border: 2px solid $backgroundColorBlue;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      right: 20px;
      top: 0;
      bottom: 0;
      width: 25px;
      background: transparent url('../../images/icons/arrow_down_white.svg')
        right 0 center no-repeat;
      background-size: 22.5px 14.35px;
      transform: rotate(180deg);
    }

    &.open::before {
      transform: rotate(0deg);
    }
  }

  .highlight {
    background-color: $backgroundColorLightgray;
    border: 1px solid $backgroundColorLightgray;
  }
}
