/* ---------------------------------- */
/* Style Contact */
/* ---------------------------------- */
#contacts {
  h1 {
    margin-bottom: 15px;
  }

  margin-bottom: 30px;

  .contact {
    margin-top: 25px;
    margin-bottom: 25px;

    > div {
      position: relative;
      padding-right: 30px;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      border: #063a70 solid 1px;
      border-top-right-radius: 100px;
    }

    .contactInfo {
      background: linear-gradient(
        135deg,
        rgba(0, 53, 109, 1) 15%,
        rgba(57, 109, 152, 1) 95%
      );
      padding: 30px;
      border-radius: 20px;
      position: absolute;
      bottom: -30px;
      left: 15px;
      right: 0;
    }

    h5,
    h6,
    p {
      max-width: 100%;
      color: white;
    }
    a {
      color: white;
      text-decoration: none;
      &:hover {
        color: white;
        text-decoration: none;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  #contacts {
    .contact {
      > div {
        position: relative;
        padding-right: 55px;
      }

      .contactInfo {
        bottom: -30px;
        left: 15px;
        right: 25px;
        min-height: 185px;
      }
    }
  }
}
