/* ---------------------------------- */
/* Style Fonts */
/* ---------------------------------- */

@font-face {
  font-family: 'Graphik';
  src: url('../../fonts/Graphik-Light.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Graphik-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/Graphik-Light.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/Graphik-Light.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../../fonts/Graphik-Light.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../../fonts/Graphik-Light.svg#svgFontName')
      format('svg'); /* Legacy iOS */
  font-weight: 200;
}
@font-face {
  font-family: 'Graphik';
  src: url('../../fonts/Graphik-Regular.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Graphik-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/Graphik-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/Graphik-Regular.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../../fonts/Graphik-Regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../fonts/Graphik-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('../../fonts/Graphik-Medium.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Graphik-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/Graphik-Medium.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/Graphik-Medium.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../../fonts/Graphik-Medium.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../../fonts/Graphik-Medium.svg#svgFontName')
      format('svg'); /* Legacy iOS */
  font-weight: 500;
}
@font-face {
  font-family: 'Graphik';
  src: url('../../fonts/Graphik-Semibold.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Graphik-Semibold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/Graphik-Semibold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/Graphik-Semibold.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../../fonts/Graphik-Semibold.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../fonts/Graphik-Semibold.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: 600;
}
@font-face {
  font-family: 'Graphik';
  src: url('../../fonts/Graphik-Bold.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Graphik-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/Graphik-Bold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/Graphik-Bold.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../../fonts/Graphik-Bold.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../../fonts/Graphik-Bold.svg#svgFontName')
      format('svg'); /* Legacy iOS */
  font-weight: bold;
}

body {
  font-family: 'Graphik';
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
  font-family: 'Graphik';
}

// Blue Titles
h1 {
  font-size: 30px;
  font-weight: 600;
  color: $color1;
  line-height: 1em;
  font-style: normal;
  text-align: left;
  letter-spacing: 0;
}

// Menu-items White
h2 {
  font-size: 30px;
  font-weight: 500;
  color: $color2;
  line-height: 1em;
  font-style: normal;
  text-align: left;
  letter-spacing: 0;
  margin-bottom: 15px;
}

// White Titles Big (z.b in Buttons)
h3 {
  font-size: 25px;
  font-weight: 500;
  color: $color2;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  letter-spacing: 0;
}

// White Titles smaller (z.b in Buttons)
h4 {
  font-size: 20px;
  font-weight: 500;
  color: $color2;
  line-height: 1.2em;
  font-style: normal;
  text-align: left;
  letter-spacing: 0;
}

// Filter-Item Schwarz
h5,
legend {
  font-size: 20px;
  font-weight: 500;
  color: $color3;
  line-height: 1em;
  font-style: normal;
  text-align: left;
  letter-spacing: 0;
  display: inline;
}

// Untertitel Blau
h6 {
  font-size: 16px;
  font-weight: 500;
  color: $color1;
  line-height: 1.375em;
  font-style: normal;
  text-align: left;
  letter-spacing: 0;
  margin-bottom: 10px;
}

// Normaler Text
p,
label,
ol > li,
ul > li {
  font-size: 16px;
  font-weight: 400;
  color: $color3;
  line-height: 1.375em;
  font-style: normal;
  text-align: left;
  letter-spacing: 0;
}
