/* ---------------------------------- */
/* Variablen der Farben für color */
/* ---------------------------------- */
$color1: #00356d;
$color2: white;
$color3: black;
$color4: #37cbae;
$color5: #cccccc;

/* ---------------------------------- */
/* Variablen der Farben für border-color */
/* ---------------------------------- */
$borderColorBlue: #00356d;
$borderColorBlack: black;
$borderColorWhite: white;
$borderColorGray: #cccccc;

/* ---------------------------------- */
/* Variablen der Farben für background-color */
/* ---------------------------------- */
$backgroundColorMain: white;
$backgroundColorLightgray: #cccccc;
$backgroundColorGreen: #37cbae;
$backgroundColorBlue: #2e5783;
$backgroundColorCyan: #1f7a85;

/* ---------------------------------- */
/* Variablen des Verlaufs */
/* ---------------------------------- */
$backgroundColorGradient1: #00356d;
$backgroundColorGradient2: #356db9;
$backgroundColorDeg: 156deg;
