/* ---------------------------------- */
/* Style coatingGuide */
/* ---------------------------------- */
#coatingGuide {
  /* ---------------------------------- */
  /* Style Products */
  /* ---------------------------------- */
  #products {
    margin-bottom: 50px;
    ul {
      list-style: none;
    }

    .title {
      margin-bottom: 34px;
      padding: 0;
    }

    .product {
      margin-bottom: 20px;
      border: none;
      border-radius: 7.5px;

      a {
        padding: 17px 50px 16px 20px;
        display: inline-block;
        width: 100%;
        text-decoration: none;

        background: transparent url('../../images/icons/arrow_link.svg') right
          12.5px center no-repeat;
        background-size: 22.63px 22.63px;
      }

      &:hover {
        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      }

      &.color-0 {
        background-color: $backgroundColorLightgray;
      }
      &.color-1 {
        background-color: $backgroundColorBlue;
      }
      &.color-2 {
        background-color: $backgroundColorGreen;
      }
      &.color-3 {
        background-color: $backgroundColorCyan;
      }
      &.color-4 {
        background-color: $backgroundColorMain;
        a {
          background: transparent url('../../images/icons/arrow_link_blue.svg')
            right 12.5px center no-repeat;
        }

        background-size: 22.63px 22.63px;
        border: solid #cccccc 2px;
        h4 {
          color: $color1;
        }
      }
    }
  }

  /* ---------------------------------- */
  /* Style der Startseite */
  /* ---------------------------------- */

  .homeElement {
    border-bottom: 1px solid $borderColorGray;
    margin-bottom: 20px;

    ol {
      margin-top: 10px;
      margin-left: 21px;
    }

    img {
      margin-bottom: 25px;
      width: 100%;
      height: auto;
    }

    .text {
      padding-right: 42px;
      margin-bottom: 25px;
    }

    @media only screen and (max-width: 991.98px) {
      .text {
        padding-right: 0;
        margin-bottom: 25px;
      }
    }

    h1 {
      margin-bottom: 15px;
    }

    .buttons {
      margin-top: 20px;
      margin-bottom: 20px;

      button {
        width: 100%;
        border: none;
        border-radius: 7.5px;
        padding: 17px 20px 16px 20px;
      }
    }

    &#gotIt {
      .gotitButton {
        background-color: $backgroundColorLightgray;
      }

      .filtersButton {
        background: $backgroundColorGreen
          url('../../images/icons/filter_white.svg') no-repeat right 15px center;
        background-size: 25px 25px;
      }
    }
  }
}
