/* ---------------------------------- */
/* Style Heatzian Pressure */
/* ---------------------------------- */
#hertzianPressure {
  margin-bottom: 100px;
  h1 {
    margin-bottom: 20px;
  }

  fieldset {
    margin-bottom: 10px;
  }

  .input-group {
    .input-group-prepend,
    .input-group-append {
      .input-group-text {
        color: $color2;
        background-color: $backgroundColorBlue;
        border: 1px solid $backgroundColorBlue;
      }
    }
  }
  .form-control {
    color: $backgroundColorBlue;
    border: 1px solid $backgroundColorBlue;
  }

  button.submit {
    width: 100%;
    border: none;
    border-radius: 7.5px;
    padding: 17px 20px 16px 20px;
    background-color: $backgroundColorGreen;

    margin-bottom: 30px;
  }

  .result {
    border-top: 1px solid black;
    padding: 21px 0;
  }
  .text {
    a {
      color: $color1;
    }
  }
}
