/* ---------------------------------- */
/* Import Color Variablen und Font style */
/* ---------------------------------- */
@import './scss/colors';
@import './scss/fonts';

/* ---------------------------------- */
/* Start Style */
/* ---------------------------------- */
* {
  margin: 0;
  padding: 0;
  outline: none !important;
}

html,
body {
  background-color: white;
}

/* ---------------------------------- */
/* Import alle scss */
/* ---------------------------------- */
@import './scss/_bootstrapOverride';
@import './scss/main';
@import './scss/filterOverlay';
@import './scss/coatingGuide';
@import './scss/hertzianPressure';
@import './scss/unitConversion';
@import './scss/demoldingPlastic';
@import './scss/contacts';
